'use client';

import dynamic from 'next/dynamic';
import { useRouter } from 'next/navigation';
import { ComponentProps, FC, useCallback, useEffect, useState } from 'react';
import shallow from 'zustand/shallow';

import { authStore } from 'src/stores/authStore';
import { cartStore } from 'src/stores/cartStore';

import { PURCHASE_INFORMATION_STATUS } from '../../PurchaseInformation/PurchaseInformation';
import { HeaderMenuData } from '../HeaderMenu.type';
import { Menu } from '../Menu/Menu';
import { getCarPurchaseStatus, isMediumScreen } from './AccountPopper.helpers';
import { PopperTrigger } from './PopperTrigger';

const PopperMenu = dynamic(() => import('./PopperMenu').then((module) => module.PopperMenu));
const DialogMenu = dynamic(() => import('./DialogMenu').then((module) => module.DialogMenu));

interface Props {
  headerData: HeaderMenuData;
}

export const AccountPopper: FC<Props> = ({ headerData }) => {
  const router = useRouter();
  const { isUserLoggedIn } = authStore(({ isUserLoggedIn }) => ({ isUserLoggedIn }), shallow);
  const { carData, cartExpiry, carPurchaseStatus } = cartStore((state) => {
    // eslint-disable-next-line no-nested-ternary
    const carPurchaseStatus: PURCHASE_INFORMATION_STATUS = getCarPurchaseStatus(
      state.cartExpired,
      state.carData?.state,
    );
    return { carData: state.carData, cartExpiry: state.cartExpiry, carPurchaseStatus };
  }, shallow);
  const [popperOpen, setPopperOpen] = useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  // Not using useRef based on documentation: https://floating-ui.com/docs/react#external-elements
  const [anchor, setAnchor] = useState<Element | null>(null);

  const onOpenMenu = useCallback(() => {
    if (isMediumScreen()) {
      setDialogOpen(true);
    } else if (isUserLoggedIn) {
      setPopperOpen(true);
    } else {
      router.push(`/signin?redirect=${encodeURIComponent(window?.location.toString() ?? '/')}`);
    }
  }, [isUserLoggedIn, setDialogOpen, setPopperOpen]);

  const closeMenu = useCallback(() => {
    setPopperOpen(false);
    setDialogOpen(false);
  }, []);

  useEffect(() => {
    // Handle closing menu if user is signed-out in the background
    if (!isUserLoggedIn) {
      closeMenu();
    }
  }, [isUserLoggedIn, closeMenu]);

  const menuProps: ComponentProps<typeof Menu> = {
    headerData,
    isUserLoggedIn,
    onItemClick: closeMenu,
    purchaseInformation: {
      carData,
      cartExpiry,
      status: carPurchaseStatus,
    },
  };

  return (
    <>
      <PopperTrigger ref={setAnchor} onClick={onOpenMenu} popperOpen={popperOpen} />
      <PopperMenu anchor={anchor} isOpen={popperOpen} setIsOpen={setPopperOpen}>
        <Menu {...menuProps} desktopMenu />
      </PopperMenu>
      <DialogMenu anchor={anchor} isOpen={dialogOpen} setIsOpen={setDialogOpen}>
        <Menu {...menuProps} />
      </DialogMenu>
    </>
  );
};
