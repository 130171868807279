import { ComponentProps, FC } from 'react';
import useSWRImmutable from 'swr/immutable';

import { fetchVehicleFilters } from 'src/data/ProductApi/ProductApiListVehicles';

import { KeywordSearch } from '../KeywordSearch/KeywordSearch';
import { HeaderMenu } from './HeaderMenu';

/** @deprecated Please use the non legacy version of this component if you're in NextJS App router */
export const HeaderMenuLegacy: FC<{ headerData: ComponentProps<typeof HeaderMenu>['headerData'] }> = ({
  headerData,
}) => {
  const { data: vehicleFilters } = useSWRImmutable('/vehicle-filters', fetchVehicleFilters);
  return (
    <HeaderMenu keywordSearch={<KeywordSearch vehicleFilters={vehicleFilters} isMobile />} headerData={headerData} />
  );
};
