import Link from 'next/link';
import { forwardRef } from 'react';

import { IconArrowRight } from 'src/general/Icons/IconArrowRight';
import { Image } from 'src/general/components/Image/Image';
import { getLinkType } from 'src/general/helpers/getLinkType';

import { CTAButton } from '../../Button/CTAButton';
import { HeaderMenuNavigationItem } from '../HeaderMenu.type';
import styles from './SubmenuFaq.module.scss';

interface Props {
  submenu: Extract<HeaderMenuNavigationItem, { type: 'submenu_faq' }>;
  onItemClick?: () => void;
}

export const SubmenuFaq = forwardRef<HTMLElement, Props>(
  ({ submenu: { submenu: submenuContent }, onItemClick }, ref) => {
    return (
      <nav className={styles.root} ref={ref}>
        <header className={styles.header}>
          <h2 className={styles.headerTitle}>{submenuContent.title}</h2>
          {submenuContent.ctas.length > 0 && (
            <CTAButton {...submenuContent.ctas[0]} onClick={onItemClick} size="small" />
          )}
        </header>
        <ul className={styles.menu}>
          {submenuContent.items.map((item) => (
            <li key={item.href} className={styles.menuItem}>
              <Link href={item.href} className={styles.menuItemLink} onClick={onItemClick}>
                {!!item.image && (
                  <Image src={item.image} className={styles.menuItemLinkImage} width={40} height={40} alt="" />
                )}
                <p className={styles.menuItemLinkLabel}>{item.label}</p>
                {!!item.subtitle && <p className={styles.menuItemLinkSubtitle}>{item.subtitle}</p>}
                <IconArrowRight className={styles.menuItemArrow} />
              </Link>
            </li>
          ))}
        </ul>
        <div className={styles.faqs}>
          <h2 className={styles.faqsTitle}>{submenuContent.titleFaq}</h2>
          <ul className={styles.faqsList}>
            {submenuContent.itemsFaq.map((item) => (
              <li key={item.href} className={styles.faqsListItem}>
                <Link
                  href={item.href}
                  className={styles.faqsListItemLink}
                  onClick={onItemClick}
                  target={getLinkType(item.href) === 'external' ? '_blank' : undefined}
                >
                  {item.label}
                </Link>
              </li>
            ))}
          </ul>
          {submenuContent.ctasFaq.length > 0 && (
            <CTAButton {...submenuContent.ctasFaq[0]} onClick={onItemClick} size="small" />
          )}
        </div>
      </nav>
    );
  },
);

SubmenuFaq.displayName = 'SubmenuFaq';
