import { useEffect } from 'react';

export const lockScroll = () => {
  const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
  document.body.style.overflow = 'hidden';
  document.body.style.paddingRight = `${scrollbarWidth}px`;
};
export const unlockScroll = () => {
  document.body.style.overflow = '';
  document.body.style.paddingRight = '';
};

/**
 * Hook to manage locking/unlocking scrolling of the body element, based on the given boolean.
 */
export const useScrollLock = (scrollLocked: boolean) => {
  useEffect(() => {
    if (scrollLocked) {
      lockScroll();
      return () => unlockScroll();
    }
  }, [scrollLocked]);
};
