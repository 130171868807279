'use client';

import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Image from 'next/image';
import { FC } from 'react';

import carmaGenericImageLoader from 'src/general/helpers/carmaGenericImageLoader';

import styles from './HeaderMenu.module.scss';
import { HeaderMenuData } from './HeaderMenu.type';

interface Props {
  headerData: HeaderMenuData;
}

const LOGO_WIDTH = 162;
const LOGO_HEIGHT = 64;

export const HeaderMenuLogo: FC<Props> = ({ headerData }) => {
  const flags = useFlags();
  return (
    <>
      <Image
        src={flags?.easterLogo ? '/images/brand/easter/easter-carma-logo.png' : headerData.logo}
        alt="Carma"
        className={styles.logoImage}
        width={LOGO_WIDTH}
        height={LOGO_HEIGHT}
        loader={carmaGenericImageLoader}
        priority
      />
      <Image
        src={flags?.easterLogo ? '/images/brand/easter/easter-carma-logo-white.png' : headerData.logoTransparent}
        alt="Carma"
        className={classNames(styles.logoImage, styles.logoImageTransparent)}
        width={LOGO_WIDTH}
        height={LOGO_HEIGHT}
        loader={carmaGenericImageLoader}
        priority
      />
    </>
  );
};
