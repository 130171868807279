import { ComponentProps, FC } from 'react';

import { Icon } from './Icon';

export const IconTrailing: FC<ComponentProps<typeof Icon>> = (props) => (
  <Icon {...props} viewBox="0 0 24 24">
    <path d="M12.3999 3.39999H16.9999C18.4359 3.39999 19.5999 4.56405 19.5999 5.99999V18C19.5999 19.4359 18.4359 20.6 16.9999 20.6H12.3999V19.4H16.9999C17.7731 19.4 18.3999 18.7732 18.3999 18V5.99999C18.3999 5.2268 17.7731 4.59999 16.9999 4.59999H12.3999V3.39999Z" />
    <path d="M8.50015 7.15147L4.07588 11.5757C3.84157 11.8101 3.84157 12.19 4.07588 12.4243L8.50015 16.8485L9.34867 16L5.94867 12.6H16.0999V11.4H5.94868L9.34867 8L8.50015 7.15147Z" />
  </Icon>
);
